import React from "react";
import {decimalAdjust, TIPO_STOCK} from "../../Global";
import CombosService from "../../services/CombosService";

class ListaProductosPreventa extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canShowDetail: false
        }

        this.combosService = new CombosService();
    }

    async componentDidMount() {
        const confEmpresa = await (async () => {
            try {
                const req = await fetch(`/api/config/emp`);
                const conf = await req.json();
    
                if (!('showProductDetail' in conf)) {
                    conf['showProductDetail'] = false;
                };
    
                return conf;
            } catch (e) {
                console.error(e)
            }
        })()

        this.setState({ canShowDetail: confEmpresa.showProductDetail })
    }

    handleClick = (e, producto) => {
        if (e.target.tagName.toLowerCase() != 'td') {
            return;
        }

        const rowId = `prod-${producto.IdProducto}-${producto.IdPresentacion}`;
        const row = document.getElementById(rowId);
        if (row) {
            row.scrollIntoView({ behavior: 'smooth' });
            this.props.AddProductoInList(producto);
        }
    }

    handleClickCombo = async (e, combo) => {
        let comboDetail = {
            detalles: []
        }

        try {
            comboDetail = await this.combosService.fetchDetail(combo.IdCombo);
        } catch (e) {
            console.error(e)
        }

        for (const detalle of comboDetail.detalles) {
            const candidates = await this.combosService.buscarProducto(detalle.Producto);
            const producto = candidates.find(x => x.IdPresentacion == detalle.IdPresentacion);

            if (!producto) {
                continue
            }

            await this.props.AddProductoInList(producto, detalle.Descuento);
        }
    }

    render() {
        const showDefaultColor = Boolean(this.props.showDefaultColor);
        const stockKey = this.props.stockKey
        const showStock = 'showStock' in this.props ? this.props.showStock : true;
        const style = this.props.disableHeight ? {} : { height: '100%' };

        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos table-responsive" style={style}>
                    <div className="lista_productos__item">
                        <table hidden={this.props.load} className="table table-hover">
                            <thead>
                            <tr>
                                <th style={{borderTopLeftRadius: "10px", backgroundColor: "#034b2f", color:"white"}}><span>Producto / </span><span>Presentación</span></th>
                                <th style={{backgroundColor: "#034b2f", color:"white"}}>Precio</th>
                                {showStock ? <th style={{borderTopRightRadius: "0px",backgroundColor: "#034b2f", color:"white"}}>Stock</th> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.combos.map((c, index) => {
                                    return <>
                                        <tr onClick={(e) => this.handleClickCombo(e, c)}
                                            className="prodPreventaTr"
                                            style={{cursor: "pointer"}}
                                            key={`combo-${c.IdCombo}`}
                                            title={`${c.Nombre}`}
                                            id={`combo-${c.IdCombo}`}
                                        >
                                            <td colSpan={3} className={"pb-3 text-uppercase"} style={{}}>
                                                {c.Nombre}
                                            </td>
                                        </tr>
                                    </>
                                })
                            }
                            {
                                this.props.productos.map((p, index) => {
                                    let stock = 0
                                    if (stockKey) {
                                        stock = p[stockKey]
                                    } else {
                                        const preVentaStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).Stock :
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).Stock;

                                        const currentStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE).Stock :
                                            p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE).Stock;

                                        const valeCurrentStock = p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.STOCK_COMPROMETIDO_VALE).Stock

                                        stock = (currentStock) / p.TipoCambio;
                                    }

                                    let color = showDefaultColor ? "" : (p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d")

                                    return (
                                        <>
                                        <tr onClick={(e) => this.handleClick(e, p)}
                                            className="prodPreventaTr"
                                            style={{cursor: "pointer"}}
                                            key={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                                            title={`${p.descripcion}-${p.unidadMedida}`}
                                            id={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                                        >
                                            <td style={{}}
                                                bgcolor={color}>
                                                {this.state.canShowDetail && <button onClick={(e) => 'onToggleDetail' in this.props && this.props.onToggleDetail(e, index)} className="btn btn-sm btn-info p-1 mr-2"><i className={p._showDetail ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i></button>}
                                                {p.descripcion} {p.unidadMedida}
                                            </td>
                                            <td rowSpan={p._showDetail ? 2 : 1} bgcolor={"#01a59c"}
                                                style={{padding: '5px 0'}}>
                                                <label
                                                    style={{color: "white"}}>{p.moneda}</label>

                                                <div className="">
                                                    <div style={{color: "white", textAlign: "end"}}>
                                                        {decimalAdjust('floor', p.precio, -2)}
                                                    </div>
                                                </div>
                                            </td>
                                            {showStock ? (
                                                <td rowSpan={p._showDetail ? 2 : 1} style={{
                                                    backgroundColor: "rgb(53,96,90,0.18)",
                                                    borderTop: "1px solid white"
                                                }}>
                                                    <div style={{textAlign: "end"}}>
                                                        {
                                                            decimalAdjust('floor', stock, -2)
                                                        }
                                                    </div>
                                                </td>
                                            ) : null}
                                        </tr>
                                        { p._showDetail && <tr>
                                            <td>
                                                <span><strong>Ubicación:</strong> {p.Ubicacion}</span>
                                            </td>
                                        </tr> }
                                        </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ListaProductosPreventa;
